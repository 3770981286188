import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, withCtx as _withCtx, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-3f7d0188"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "content-header" }
const _hoisted_2 = { class: "section-header" }
const _hoisted_3 = {
  key: 0,
  class: "content-blank"
}
const _hoisted_4 = { key: 1 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_BreadScrum = _resolveComponent("BreadScrum")!
  const _component_ItemInList = _resolveComponent("ItemInList")!
  const _component_AfterLoginPage = _resolveComponent("AfterLoginPage")!

  return (_openBlock(), _createBlock(_component_AfterLoginPage, { onScrolledToBottom: _ctx.handleLoad }, {
    "top-part": _withCtx(() => [
      _createVNode(_component_BreadScrum, { paths: _ctx.dataBreadScrumLinks }, null, 8, ["paths"])
    ]),
    "left-top-part": _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("p", null, _toDisplayString(_ctx.$t("company.monthlyReportDate")), 1),
          _createElementVNode("p", null, _toDisplayString(_ctx.$t("company.monthlyReportSignedTime")), 1),
          _createElementVNode("p", null, _toDisplayString(_ctx.$t("company.monthlyReportCreateContract")), 1),
          _createElementVNode("p", null, _toDisplayString(_ctx.$t("company.monthlyReportAverageCountSigner")), 1)
        ])
      ])
    ]),
    "left-bottom-part": _withCtx(() => [
      (_ctx.monthlyReportList.length === 0)
        ? (_openBlock(), _createElementBlock("div", _hoisted_3, _toDisplayString(_ctx.$t("company.monthlyReportNoItem")), 1))
        : (_openBlock(), _createElementBlock("div", _hoisted_4, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.monthlyReportList, (monthlyReport, index) => {
              return (_openBlock(), _createBlock(_component_ItemInList, {
                key: index,
                "has-divider": ""
              }, {
                body: _withCtx(() => [
                  _createElementVNode("p", null, _toDisplayString(_ctx.formatDateYearAndMonth(monthlyReport.date)), 1),
                  _createElementVNode("p", null, _toDisplayString(_ctx.formatDateMinuteBySeconds(monthlyReport.signedTime)), 1),
                  _createElementVNode("p", null, _toDisplayString(monthlyReport.createdContract), 1),
                  _createElementVNode("p", null, _toDisplayString(monthlyReport.averageCountSigner), 1)
                ]),
                _: 2
              }, 1024))
            }), 128))
          ]))
    ]),
    _: 1
  }, 8, ["onScrolledToBottom"]))
}