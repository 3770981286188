
import { defineComponent, onMounted, ref, computed } from "vue";
import { useI18n } from "vue-i18n";
import { useRoute } from "vue-router";
import { RepositoryFactory, CompanyRepository } from "@/lib/https";
import BreadScrum from "@/components/atomics/BreadScrum.vue";
import useList from "@/lib/compositional-logic/useList";
import ItemInList from "@/components/atomics/list-item/ItemInList.vue";
import AfterLoginPage from "@/views/layouts/AfterLoginAdminPage.vue"
import { formatDateYearAndMonth, formatDateMinuteBySeconds } from "@/lib/utility/dateTime"

export default defineComponent({
  name: "MonthlyReport",
  components: {
    BreadScrum,
    ItemInList,
    AfterLoginPage
  },
  setup() {
    const { t } = useI18n();
    const route = useRoute();

    const companyId = route.params.id;
    const dataBreadScrumLinks = ref([
      { text: t("company.titleListCompanies"), to: "/company" },
      { text: "", to: "/company/" + companyId },
      { text: t("company.monthlyReportTitle"), to: "" },
    ]);

    const { getCompanySimple } = RepositoryFactory.getRepository<CompanyRepository>(CompanyRepository);

    const getCompanyInfo = async () => {
      const response = await getCompanySimple(companyId.toString());
      if (!response) return;
      dataBreadScrumLinks.value[1].text = response.name
    };

    onMounted(getCompanyInfo);

    const { getMonthlyReportList } = RepositoryFactory.getRepository<CompanyRepository>(CompanyRepository);

    const {
      list: monthlyReportList,
      loadMore,
    } = useList({
      initialLoadList: async ({ page, pageSize }) =>
        await getMonthlyReportList(companyId as string, { page, pageSize }),
      loadMoreList: async ({ page, pageSize }) =>
        await getMonthlyReportList(companyId as string, { page, pageSize }),
    });

    const handleLoad = computed(() =>loadMore);

    return {
      dataBreadScrumLinks,
      monthlyReportList,
      handleLoad,
      formatDateYearAndMonth,
      formatDateMinuteBySeconds,
    }
  }
});
